.Homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100vh;
  background: url(/public/sharebnb-background.jpg) no-repeat center center;
  background-size: cover;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  padding-top: 60px;
}

.main-container {
  background: rgba(0, 0, 0, 0.6);
  padding: 40px;
  border-radius: 10px;
  margin-bottom: 30px;
}

h1 {
  font-size: 3rem;
}

.lead {
  font-size: 1.5rem;
}

.auth-buttons {
  margin-top: 20px;
}

.btn {
  font-size: 1.2rem;
}

/* Feature Cards */
.feature-container {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-bottom: 40px;
}

.feature-card {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.2s ease-in-out;
}

.feature-card:hover {
  transform: scale(1.05);
}

.feature-title {
  font-size: 1.5rem;
  margin-top: 15px;
}

.feature-text {
  font-size: 1rem;
  color: #666;
}

.icon {
  font-size: 3rem;
  color: #17a2b8;
}

.tree-icon {
  color: #28a745;
}

.pool-icon {
  color: #007bff;
}

/* Login Modal */
.login-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  position: relative;
  max-width: 400px;
  width: 100%;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}